import "./App.css";
import { Sunflower } from "./models/Sunflower";
import { Environment, OrbitControls } from "@react-three/drei";

function App() {
  return (
    <>
      <Environment preset="warehouse" />
      <ambientLight intensity={7} />
      <Sunflower scale={13} position={[0, -4, 0]} />
      <OrbitControls
        enableZoom={false}
        maxPolarAngle={Math.PI / 2}
        minPolarAngle={Math.PI / 2}
        rotation={[0]}
      />
    </>
  );
}

export default App;
