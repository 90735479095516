/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 Sunflower.glb --transform 
Files: Sunflower.glb [69.38MB] > C:\Users\mercu\Work\arteryheating.co.uk\httpdocs\public\sunflower\Sunflower-transformed.glb [614.44KB] (99%)
*/

import React from "react";
import { useGLTF } from "@react-three/drei";

export function Sunflower(props) {
  const { nodes, materials } = useGLTF("./sunflower/Sunflower-transformed.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Sunflower.geometry}
        material={materials.Sunflower_Base}
        position={[0, 0, 0]}
      />
      <mesh
        // geometry={nodes.Floor.geometry}
        // material={materials.Floor}
        position={[0, 0, 0]}
        // rotation={[-0, -0, -0]}
        rotation-y={[-5]}
        scale={0.005}
      />
    </group>
  );
}

useGLTF.preload("./sunflower/Sunflower-transformed.glb");
